import * as React from "react";
import { makeStyles, Button, Label, Text, typographyStyles } from "@fluentui/react-components";
import { AddFilled, ArrowRotateClockwiseFilled } from "@fluentui/react-icons";
import { Link } from "react-router-dom";
import QueryList from "./QueryList";
import {
  onCellSelectionChanged,
  subscribeToSelectionChange,
  unsubscribeFromSelectionChange,
} from "../../utilities/excel";
import { useQueryContext } from "../context/QueryContext";
import { getConnectors } from "../../services/connector";
import { useQuery } from "react-query";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    padding: "15px",
    gap: "15px",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  manageQueries: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  paragraph: {
    ...typographyStyles.body1,
    letterSpacing: "0.0675em",
    fontStyle: "italic",
  },
});

const QueryManager = ({ setLoginInfo, refetchUserInfo }) => {
  const styles = useStyles();
  const { queries, loadQueriesFromSheet, setIsEditing } = useQueryContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedQueryId, setSelectedQueryId] = React.useState(null);
  const token = localStorage.getItem("DS-TOKEN");
  React.useEffect(() => {
    const onSelectionChange = (eventArgs) => onCellSelectionChanged(eventArgs, queries, handleSelectQuery);
    subscribeToSelectionChange(onSelectionChange);

    return () => {
      unsubscribeFromSelectionChange(onSelectionChange);
    };
  }, [queries]);

  const handleSelectQuery = (queryUUID) => {
    setSelectedQueryId(queryUUID);
  };

  const { data: connectors, refetch } = useQuery(
    ["datasources", token],
    async () => {
      const response = await getConnectors({ token });
      if (response?.ok) {
        return await response.json();
      }
      throw new Error("Error fetching models");
    },
    {
      enabled: false,
      staleTime: Infinity,
    }
  );

  return (
    <div className={styles.root}>
      <div className={styles.manageQueries} id="manage-queries">
        <Label>
          Manage Queries
          <Button
            onClick={() => {
              loadQueriesFromSheet(setIsLoading);
              refetch();
              setLoginInfo();
            }}
            appearance="transparent"
            icon={<ArrowRotateClockwiseFilled className={isLoading ? "spin" : ""} />}
          />
        </Label>
      </div>
      <div id="manage-queries">
        <Link to="/add-query" relative="path">
          <Button
            onClick={() => {
              setIsEditing(false);
            }}
            className="w-full"
            appearance="primary"
            icon={<AddFilled />}
          >
            Add New Query
          </Button>
        </Link>
      </div>
      <div>
        {queries.length > 0 ? (
          <QueryList selectedQueryId={selectedQueryId} setIsloading={setIsLoading} refetchUserInfo={refetchUserInfo} />
        ) : (
          <Text as="p" block className={styles.paragraph}>
            You haven't created any queries yet
          </Text>
        )}
      </div>
      <div id="add-query"></div>
    </div>
  );
};

export default QueryManager;

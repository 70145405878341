export const onFormatDate = (date) => {
  return !date
    ? ""
    : date.getFullYear() + "-" + `0${date.getMonth() + 1}`.slice(-2) + "-" + `0${date.getDate()}`.slice(-2);
};

export function formatDateTime(date, timeZone, format) {
  const options = {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  const parts = new Intl.DateTimeFormat("en-GB", options).formatToParts(date);
  const map = {};

  parts.forEach(({ type, value }) => {
    map[type] = value;
  });

  const formattedDate = format
    .replace("yyyy", map.year)
    .replace("MM", map.month)
    .replace("dd", map.day)
    .replace("HH", map.hour)
    .replace("mm", map.minute)
    .replace("ss", map.second);

  return `'${formattedDate}`;
}

export const getInitialStartDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), 0, 1);
};

export const getInitialEndDate = () => {
  return new Date();
};

export function cleanDate(dateString) {
  if (dateString && dateString.startsWith("'")) {
    return dateString.slice(1);
  }
  return dateString;
}

export const calculatePreviousPeriod = (startDate, endDate) => {
  const periodLength = endDate - startDate;
  const newEndDate = new Date(startDate.getTime() - 1);
  const newStartDate = new Date(newEndDate.getTime() - periodLength);
  return { newStartDate, newEndDate };
};

// Función para calcular las mismas fechas pero un año antes
export const calculateSameDatesAYearAgo = (startDate, endDate) => {
  const newStartDate = new Date(startDate);
  const newEndDate = new Date(endDate);
  newStartDate.setFullYear(startDate.getFullYear() - 1);
  newEndDate.setFullYear(endDate.getFullYear() - 1);
  return { newStartDate, newEndDate };
};

// Función para calcular las fechas un año antes, ajustadas al mismo día de la semana
export const calculateSameDatesAYearAgoMatchingDay = (startDate, endDate) => {
  const daysDifferenceStart = startDate.getDay();
  const daysDifferenceEnd = endDate.getDay();

  const newStartDate = new Date(startDate);
  const newEndDate = new Date(endDate);

  newStartDate.setFullYear(startDate.getFullYear() - 1);
  newEndDate.setFullYear(endDate.getFullYear() - 1);

  newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() - daysDifferenceStart));
  newEndDate.setDate(newEndDate.getDate() - (newEndDate.getDay() - daysDifferenceEnd));

  return { newStartDate, newEndDate };
};

export const calculateDateRange = (option, xValue = 1, includeThis = false, selectedDates) => {
  const today = new Date();
  let startDate = new Date(today);
  let endDate = new Date(today);
  if (!xValue && selectedDates) {
    option = "default";
  }

  switch (option) {
    case "today":
      startDate = today;
      endDate = today;
      break;
    case "yesterday":
      // Yesterday
      startDate.setDate(today.getDate() - 1);
      endDate = new Date(startDate);
      break;
    case "lastweeksunsat":
      // Last week (Sun-Sat)
      startDate.setDate(today.getDate() - today.getDay() - 7);
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      break;
    case "lastweekmonsun":
      // Last week (Mon-Sun)
      startDate.setDate(today.getDate() - today.getDay() - 6);
      endDate.setDate(startDate.getDate() + 6);
      break;
    case "thismonth":
      // This month to date
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      break;
    case "thismonthtoyesterday":
      // This month to yesterday
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate.setDate(today.getDate() - 1); // Ayer
      break;
    case "lastmonth":
      // Last month
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      break;
    case "thisyear":
      // Year to date
      startDate = new Date(today.getFullYear(), 0, 1);
      break;
    case "lastyear":
      // Last year
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today.getFullYear() - 1, 11, 31);
      break;
    case "lastyeartodate":
      // Last year & this year to date
      startDate = new Date(today.getFullYear() - 1, 0, 1);
      endDate = new Date(today);
      break;
    case "lastxdays":
      // Last X days
      startDate.setDate(today.getDate() - (includeThis ? xValue - 1 : xValue));
      break;
    case "lastxweeks":
      // Last X weeks (Sun-Sat)
      if (includeThis) {
        const proposedEndDate = new Date(today);
        proposedEndDate.setDate(today.getDate() + (6 - today.getDay()));
        endDate = proposedEndDate > today ? today : proposedEndDate;
      } else {
        endDate.setDate(today.getDate() - today.getDay() - 1);
      }
      startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - (endDate.getDay() + 7 * (xValue - 1)));
      break;
    case "lastxweeksiso":
      // Last X weeks (Mon-Sun)
      if (includeThis) {
        const proposedEndDate = new Date(today);
        proposedEndDate.setDate(today.getDate() + (7 - (today.getDay() || 7)));
        endDate = proposedEndDate > today ? today : proposedEndDate;
      } else {
        endDate.setDate(today.getDate() - (today.getDay() || 7));
      }
      startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - ((endDate.getDay() || 7) + 7 * (xValue - 1) - 1));
      break;
    case "lastxmonths":
      // Last X months
      if (includeThis) {
        startDate = new Date(today.getFullYear(), today.getMonth() - xValue + 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      } else {
        startDate = new Date(today.getFullYear(), today.getMonth() - xValue, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
      }
      break;
    case "lastxyears":
      // Last X years
      if (includeThis) {
        startDate = new Date(today.getFullYear() - xValue + 1, 0, 1);
        endDate = new Date(today);
      } else {
        startDate = new Date(today.getFullYear() - xValue, 0, 1);
        endDate = new Date(today.getFullYear() - 1, 11, 31);
      }
      break;
    default:
      startDate = selectedDates ? selectedDates.start_date : new Date(today.getFullYear(), 0, 1);
      endDate = selectedDates ? selectedDates.end_date : today;
      break;
  }

  return { startDate, endDate };
};

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const getSelectedDateRangeOption = (dateRangeType) => {
  if (dateRangeType.startsWith("last") && !isNaN(dateRangeType.charAt(4))) {
    const match = dateRangeType.match(/(\d+)/);
    const lastItemsInRange = match ? match[0] : null;
    let includeCurrent = false;
    if (dateRangeType.endsWith("inc")) {
      includeCurrent = true;
      dateRangeType = `lastx${dateRangeType.substring(4 + lastItemsInRange.length, dateRangeType.length - 3)}`;
    } else {
      dateRangeType = `lastx${dateRangeType.substring(4 + lastItemsInRange.length)}`;
    }

    return {
      rangeType: dateRangeType,
      lastItemsInRange,
      includeCurrent,
    };
  }

  return {
    rangeType: dateRangeType,
    lastItemsInRange: "",
    includeCurrent: false,
  };
};

import React from "react";
import { Routes, Route } from "react-router-dom";
import QueryManager from "./QueryManager";
import MainAccordion from "./MainAccordion";
import ScheduleManager from "./ScheduleManager";
import useRefreshTokenWatcher from "../../utilities/useRefreshTokenWatcher";

const Content = ({
  loginInfo,
  setLoginInfo,
  setSecret,
  setOneDriveAuth,
  refreshSchedules,
  setCurrentUsage,
  refetchUserInfo,
}) => {
  useRefreshTokenWatcher();
  return (
    <div id="main-content" className="mb-24">
      <Routes>
        <Route
          exact
          path="/"
          element={<QueryManager setLoginInfo={setLoginInfo} refetchUserInfo={refetchUserInfo} />}
        />
        <Route path="/authorize" element={<div>Hola mundo</div>} />
        <Route
          path="add-query"
          element={
            <MainAccordion
              title="CONNECTORS"
              loginInfo={loginInfo}
              setLoginInfo={setLoginInfo}
              setCurrentUsage={setCurrentUsage}
              refetchUserInfo={refetchUserInfo}
            />
          }
        />
        <Route
          exact
          path="schedules"
          element={
            <ScheduleManager
              setSecret={setSecret}
              setOneDriveAuth={setOneDriveAuth}
              refreshSchedules={refreshSchedules}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default Content;

import { fetchAPI } from "./base";

export function getTemplates() {
  return fetchAPI({
    endpoint: `hub/get_templates`,
  });
}

export function saveSchedule(service_id, user, body) {
  return fetchAPI({
    endpoint: `schedules/save_schedule?service_id=${service_id}&user_email=${user}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function deleteSchedule(service_id, user, schedule_id) {
  return fetchAPI({
    endpoint: `schedules/delete_schedule?service_id=${service_id}&user_email=${user}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      schedule_id: schedule_id,
    }),
  });
}

export function getAllSchedules({ user_email, service_id, document_url }) {
  const params = new URLSearchParams({ user_email, service_id, document_url });
  return fetchAPI({
    endpoint: `schedules/get_schedules_from_document?${params.toString()}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

export function updateSchedule(user_email, data) {
  const params = new URLSearchParams({ user_email });
  return fetchAPI({
    endpoint: `schedules/update_schedule?${params.toString()}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

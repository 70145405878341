import * as XLSX from "xlsx";
import {
  downloadFileFromOneDrive,
  getOneDriveFileMetadata,
  getOneDrivePermissions,
  renameFileOnOneDrive,
  uploadFileToOneDrive,
} from "../services/login";
import { config } from "../Constants";
import { ensureValidAccessToken } from "../services/auth";

export function generateUserCode(length = 32) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const ramdomValues = new Uint8Array(length);

  window.crypto.getRandomValues(ramdomValues);

  return Array.from(ramdomValues)
    .map((val) => chars[val % chars.length])
    .join("");
}

export const authorizeOneDrive = async (setSecret) => {
  const hasPermissions = await checkOneDrivePermissions();
  if (hasPermissions) {
    return;
  }

  const accessToken = localStorage.getItem("DS-EXCEL-TOKEN");

  if (!accessToken) {
    return;
  }

  try {
    const codigo = generateUserCode(32);
    localStorage.setItem("DS-SECRET", codigo);
    setSecret(codigo);
    const authUrl = `${config.M_AUTHORITY}/authorize?client_id=${config.CLIENT_ID}&response_type=${config.RESPONSE_TYPE}&redirect_uri=${config.REDIRECT_URI}?secret=${encodeURIComponent(codigo)}&code_challenge=${config.CODE_CHALLENGE}&code_challenge_method=${config.CODE_CHALLENGE_METHOD}&scope=${config.ONE_DRIVE_SCOPES.join("+")}&prompt=consent`;
    window.open(authUrl, "_blank");
  } catch (error) {
    console.log("Error in authorizeOneDrive:", error);
  }
};

export const checkOneDrivePermissions = async () => {
  try {
    const accessToken = await ensureValidAccessToken();

    const response = await getOneDrivePermissions(accessToken);

    if (response.ok) {
      return accessToken;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking permissions:", error);
    return false;
  }
};

async function protectOneDriveInfoSheet(context) {
  try {
    const sheetName = "OneDriveInfo";
    const sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);

    await context.sync();

    if (!sheet.isNullObject) {
      sheet.protection.load("protected");
      await context.sync();

      if (!sheet.protection.protected) {
        sheet.protection.protect();
        await context.sync();
      }
    }
  } catch (error) {
    console.error("Error protecting the sheet:", error);
  }
}

async function unprotectOneDriveInfoSheet(context) {
  try {
    const sheetName = "OneDriveInfo";
    const sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);

    await context.sync();

    if (!sheet.isNullObject) {
      sheet.protection.load("protected");
      await context.sync();

      if (sheet.protection.protected) {
        sheet.protection.unprotect();
        await context.sync();
      }
    }
  } catch (error) {
    console.error("Error unprotecting the sheet:", error);
  }
}

async function getExcelFileName() {
  return new Promise((resolve, reject) => {
    try {
      Office.context.document.getFilePropertiesAsync((result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const filePath = result.value.url;
          console.log("filePath", filePath);
          if (filePath) {
            const fileName = filePath.split(/[/\\]/).pop();
            resolve(fileName);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    } catch (error) {
      resolve(null);
    }
  });
}

export async function syncWithOneDrive(updateMessage) {
  try {
    const accessToken = await checkOneDrivePermissions();
    if (!accessToken) {
      return;
    }

    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    const context = new Excel.RequestContext();
    let currentFileUrl = Office.context.document.url;
    let currentFileName;
    if (!currentFileUrl || currentFileUrl.includes("?omexsrctype=1")) {
      currentFileName = await getExcelFileName();
    } else {
      currentFileName = currentFileUrl.split(/[/\\]/).pop();
    }
    if (!currentFileName || currentFileName.includes("?omexsrctype")) {
      updateMessage(
        "The file is temporary or has not been saved locally, preventing the retrieval of its name or ID for OneDrive synchronization. Please save the file first and try again."
      );
      return;
    }
    let storedFileId = await getOrSaveOneDriveFileId(context);
    const fileBuffer = await getFileAsArrayBuffer();
    if (!fileBuffer) {
      updateMessage("Could not read the file contents. Please try again.");
      return;
    }

    // Check if the file is open from OneDrive and no stored fileId
    if (!storedFileId && currentFileUrl.includes("https://d.docs.live.net")) {
      // Get the file ID from Microsoft Graph
      const newFileId = await getFileIdFromOneDrive(accessToken, currentFileName);
      if (newFileId) {
        // Save the file ID in the hidden sheet
        await unprotectOneDriveInfoSheet(context);
        await getOrSaveOneDriveFileId(context, newFileId);
        await protectOneDriveInfoSheet(context);
        // storedFileId = newFileId;
        updateMessage(`Last updated: ${formattedTime}`);
        return { success: true, url: currentFileUrl, name: currentFileName, fileId: newFileId };
      } else {
        updateMessage("Could not fetch file ID from OneDrive. Please try again.");
        return { success: false, message: "Failed to fetch file ID." };
      }
    }

    if (storedFileId && currentFileUrl.includes("https://d.docs.live.net")) {
      updateMessage(`Last updated: ${formattedTime}`);
      return { success: true, url: currentFileUrl, name: currentFileName, fileId: storedFileId };
    }

    if (!storedFileId && !currentFileUrl.includes("https://d.docs.live.net")) {
      const response = await uploadFileToOneDrive(accessToken, fileBuffer, currentFileName);
      if (response.ok) {
        const data = await response.json();
        const newFileId = data.id;

        await unprotectOneDriveInfoSheet(context);
        await getOrSaveOneDriveFileId(context, newFileId);
        await protectOneDriveInfoSheet(context);

        // localStorage.setItem("lastSyncedFileName", currentFileName);
        // updateMessage(`Last updated: ${formattedTime}`);
        const updatedFileBuffer = await getFileAsArrayBuffer();
        const updateResponse = await uploadFileToOneDrive(accessToken, updatedFileBuffer, currentFileName, newFileId);

        if (updateResponse.ok) {
          localStorage.setItem("lastSyncedFileName", currentFileName);
          updateMessage(`Last updated: ${formattedTime}`);
          return { success: true, url: data.webUrl, name: currentFileName, fileId: newFileId };
        } else {
          updateMessage("Failed to upload the updated file to OneDrive.");
          return { success: false, message: "Failed to upload file." };
        }
      }
    }

    if (storedFileId && !currentFileUrl.includes("https://d.docs.live.net")) {
      const response = await uploadFileToOneDrive(accessToken, fileBuffer, currentFileName, storedFileId);

      if (response.ok) {
        const updatedData = await response.json();
        const storedFileName = localStorage.getItem("lastSyncedFileName");
        if (storedFileName !== currentFileName) {
          await renameFileOnOneDrive(accessToken, storedFileId, currentFileName);
          localStorage.setItem("lastSyncedFileName", currentFileName);
        }
        updateMessage(`Last updated: ${formattedTime}`);
        return { success: true, url: updatedData.webUrl, name: currentFileName, fileId: storedFileId };
      }
    }
  } catch (error) {
    updateMessage(error.message || "An unexpected error occurred during sync. Please try again.");
    return { success: false, message: error.message };
  }
}

export const getFileWebUrlById = async (accessToken, fileId) => {
  const endpoint = `https://graph.microsoft.com/v1.0/me/drive/items/${fileId}`;

  try {
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error?.message || "Failed to get file info from OneDrive.");
    }

    const fileInfo = await response.json();
    return fileInfo.webUrl; // Devuelve solo la URL web del archivo
  } catch (error) {
    console.error("Error in getFileWebUrlById:", error);
    throw error;
  }
};

const getFileIdFromOneDrive = async (accessToken, fileUrl) => {
  try {
    const endpoint = `https://graph.microsoft.com/v1.0/me/drive/root:/${fileUrl}`;
    // Realiza la solicitud a Microsoft Graph
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.id; // Devuelve el ID del archivo
    } else {
      const errorData = await response.json();
      console.error("Error fetching file ID from OneDrive:", errorData);
      throw new Error(`Failed to fetch file ID from OneDrive: ${errorData?.error?.message || response.statusText}`);
    }
  } catch (error) {
    console.error("Error in getFileIdFromOneDrive:", error);
    throw error;
  }
};

export async function getOrSaveOneDriveFileId(context, fileId = null) {
  try {
    await unprotectOneDriveInfoSheet(context);

    const sheetName = "OneDriveInfo";
    let sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
    context.trackedObjects.add(sheet);

    await context.sync();

    if (!sheet.isNullObject) {
      const fileIdRange = sheet.getRange("A1");
      context.trackedObjects.add(fileIdRange);
      fileIdRange.load("values");

      await context.sync();

      const storedFileId = fileIdRange.values[0][0];

      if (fileId) {
        fileIdRange.values = [[fileId]];
        await context.sync();
        await protectOneDriveInfoSheet(context);
        context.trackedObjects.remove(fileIdRange);
        context.trackedObjects.remove(sheet);
        return fileId;
      } else {
        await protectOneDriveInfoSheet(context);
        context.trackedObjects.remove(fileIdRange);
        context.trackedObjects.remove(sheet);
        return storedFileId;
      }
    } else {
      sheet = context.workbook.worksheets.add(sheetName);
      sheet.getRange("A1").values = [[fileId]];
      sheet.visibility = Excel.SheetVisibility.hidden;
      context.trackedObjects.add(sheet);

      await context.sync();
      await protectOneDriveInfoSheet(context);
      context.trackedObjects.remove(sheet);
      return fileId;
    }
  } catch (error) {
    console.error("Error in getOrSaveOneDriveFileId:", error);
    return null;
  }
}

export async function getFileAsArrayBuffer() {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 }, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const file = result.value;
        const fileSlices = [];
        let currentSlice = 0;
        const readSlice = () => {
          file.getSliceAsync(currentSlice, (sliceResult) => {
            if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
              fileSlices.push(sliceResult.value.data);
              currentSlice++;

              if (currentSlice < file.sliceCount) {
                readSlice();
              } else {
                file.closeAsync();
                const arrayBuffer = combineSlices(fileSlices);
                resolve(arrayBuffer);
              }
            } else {
              file.closeAsync();
              reject(new Error("Error reading file slices."));
            }
          });
        };

        readSlice();
      } else {
        reject(new Error("Failed to get file. Please exit cell edit mode and try again."));
      }
    });
  });
}

function combineSlices(slices) {
  const flatSlices = slices.flat();
  const combinedArray = new Uint8Array(flatSlices);
  return combinedArray.buffer;
}

export async function checkAndUpdateFromOneDrive(updateMessage) {
  try {
    await Excel.run(async (context) => {
      await unprotectOneDriveInfoSheet(context);

      const storedFileId = await getOrSaveOneDriveFileId(context);
      if (!storedFileId) {
        await protectOneDriveInfoSheet(context);
        return;
      }

      const accessToken = await checkOneDrivePermissions();
      if (!accessToken) {
        updateMessage("Please authorize access to OneDrive to enable syncing.");
        await protectOneDriveInfoSheet(context);
        return;
      }

      const oneDriveFile = await getOneDriveFileMetadata(accessToken, storedFileId);
      const localLastModified = await getLastModifiedTimeFromSheet(context);

      if (oneDriveFile && new Date(oneDriveFile.lastModifiedDateTime) > localLastModified) {
        const fileContent = await downloadFileFromOneDrive(accessToken, storedFileId);
        if (fileContent) {
          const sheetData = convertBinaryToCellData(fileContent);
          await insertDataInExcel(sheetData);
          await saveLastModifiedTimeToSheet(context, new Date(oneDriveFile.lastModifiedDateTime));
          updateMessage("File updated with the latest version from OneDrive.");
        }
      }
      await protectOneDriveInfoSheet(context);
    });
  } catch (error) {
    console.error("Error checking for updates from OneDrive:", error);
    // showDialog("An error occurred while checking for updates from OneDrive.", []);
  }
}

async function insertDataInExcel(sheetData) {
  try {
    const context = new Excel.RequestContext();
    const workbook = context.workbook;

    workbook.worksheets.load("items/name, items/protection/protected, items/visibility");
    await context.sync();

    const sheetStates = {};
    workbook.worksheets.items.forEach((sheet) => {
      sheetStates[sheet.name] = {
        protected: sheet.protection.protected,
        visibility: sheet.visibility,
      };
    });

    for (const sheetName of Object.keys(sheetData)) {
      if (sheetStates[sheetName]) {
        const sheet = workbook.worksheets.getItem(sheetName);
        if (sheetStates[sheetName].protected) {
          sheet.protection.unprotect();
        }
        // if (sheetStates[sheetName].visibility !== Excel.SheetVisibility.visible) {
        //   sheet.visibility = Excel.SheetVisibility.visible;
        // }
      }
    }
    await context.sync();

    workbook.worksheets.items.forEach((sheet) => {
      if (sheet.name !== "OneDriveInfo" && sheet.name !== "DatasLayerQueries" && !sheetData[sheet.name]) {
        sheet.delete();
      }
    });
    await context.sync();

    for (const sheetName of Object.keys(sheetData)) {
      const cellData = sheetData[sheetName];
      let sheet;

      if (sheetStates[sheetName]) {
        sheet = workbook.worksheets.getItem(sheetName);
      } else {
        sheet = workbook.worksheets.add(sheetName);
      }

      Object.entries(cellData).forEach(([cellAddress, cellValue]) => {
        sheet.getRange(cellAddress).values = [[cellValue]];
      });

      await context.sync();
    }

    for (const [sheetName, state] of Object.entries(sheetStates)) {
      const sheet = workbook.worksheets.getItem(sheetName);
      if (state.protected) {
        sheet.protection.protect();
      }
      // if (state.visibility !== Excel.SheetVisibility.visible) {
      //   sheet.visibility = state.visibility;
      // }
    }

    await context.sync();
  } catch (error) {
    console.error("Error updating sheets in Excel:", error);
  }
}

function convertBinaryToCellData(fileContent) {
  const workbook = XLSX.read(fileContent, { type: "array" });
  const sheetData = {};

  workbook.SheetNames.forEach((sheetName) => {
    const worksheet = workbook.Sheets[sheetName];
    const cellData = {};

    Object.keys(worksheet).forEach((cellAddress) => {
      if (cellAddress[0] === "!") return;
      cellData[cellAddress] = worksheet[cellAddress].v;
    });

    sheetData[sheetName] = cellData;
  });

  return sheetData;
}

async function saveLastModifiedTimeToSheet(context, lastModifiedDate) {
  try {
    const sheet = context.workbook.worksheets.getItem("OneDriveInfo");

    sheet.protection.unprotect();
    await context.sync();

    const lastModifiedRange = sheet.getRange("A2");
    lastModifiedRange.values = [[lastModifiedDate.toISOString()]];
    await context.sync();

    sheet.protection.protect();
    await context.sync();
  } catch (error) {
    console.error("Error in saveLastModifiedTimeToSheet:", error);
    // showDialog("An error occurred while saving the last modified time. Please try again.", []);
  }
}

async function getLastModifiedTimeFromSheet(context) {
  try {
    const sheet = context.workbook.worksheets.getItem("OneDriveInfo");
    const lastModifiedRange = sheet.getRange("A2");
    lastModifiedRange.load("values");
    await context.sync();
    return lastModifiedRange.values[0][0] ? new Date(lastModifiedRange.values[0][0]) : new Date(0);
  } catch (error) {
    console.error("Error in getLastModifiedTimeFromSheet:", error);
    return new Date(0);
  }
}

import * as React from "react";
import { Checkbox, Option, makeStyles, Label, Dropdown, Field } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    padding: "0px!important",
    display: "flex",
    flexDirection: "column",
  },
  checkbox: {
    marginBottom: "10px",
  },
  select: {
    marginTop: "10px",
  },
  selectLabel: {
    display: "block",
    marginBottom: "5px",
  },
});

const OptionsAccordion = ({ options, onOptionsChange, selectedOptions }) => {
  const styles = useStyles();

  const handleOptionChange = (id, checked) => {
    onOptionsChange((prevConfig) => {
      if (checked !== "") {
        return {
          ...prevConfig,
          [id]: checked,
        };
      } else {
        const { [id]: _, ...rest } = prevConfig;
        return rest;
      }
    });
  };

  return (
    <div className="p-3">
      {options &&
        options.map((option) => (
          <div key={option.id} className={styles.container}>
            {option.type === "boolean" ? (
              <Checkbox
                className={styles.checkbox}
                checked={!!selectedOptions[option.id]}
                onChange={(e, data) => handleOptionChange(option.id, data.checked)}
                label={option.name}
              />
            ) : option.type === "list" ? (
              <>
                <Field id={option.id}>
                  <Label className={styles.selectLabel}>{option.name}</Label>
                  <Dropdown
                    selectedOptions={[selectedOptions[option.id] ?? ""]}
                    value={[selectedOptions[option.id] ?? ""]}
                    onOptionSelect={(e, data) => handleOptionChange(option.id, data.optionValue)}
                    placeholder="Select an option"
                  >
                    <Option key="" value="">
                      Select an option
                    </Option>
                    {option.values.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Dropdown>
                </Field>
              </>
            ) : null}
          </div>
        ))}
    </div>
  );
};

export default OptionsAccordion;

import { useMutation } from "react-query";
import { generateTable, generateBlendTable } from "../services/connector";
import { getUserInfo } from "../services/login";
import { config } from "../Constants";

export const useGenerateTable = (entryConnector, token, userName, showDialog, setTableTaskId, setDialogOpen) => {
  const generateTableRequest = async (type, requestData) => {
    try {
      let response;
      const resolvedEntryConnector = entryConnector || requestData.dataSource;
      if (type === "blend") {
        response = await generateBlendTable({
          hash_id: resolvedEntryConnector,
          token,
          email: userName,
          creatorUserEmail: userName,
          requestData,
        });
      } else {
        response = await generateTable({
          datasource: resolvedEntryConnector,
          token,
          email: userName,
          creatorUserEmail: userName,
          previewData: false,
          requestData,
        });
      }

      if (!response.ok) {
        throw new Error(`Error generating table: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error in generateTableRequest:", error);
      throw error;
    }
  };

  const generateTableMutation = useMutation(({ type, requestData }) => generateTableRequest(type, requestData), {
    onSuccess: (result) => {
      if (!result.ok) {
        showDialog(result.title_error, result.error);
        setDialogOpen(false);
      } else {
        if (setTableTaskId) setTableTaskId(result.task_id);
      }
    },
    onError: () => {
      showDialog("Warning message", ["There is no data for the specified input"]);
      setDialogOpen(false);
    },
  });

  const checkAvailableQueries = async (token, type, mode, queryParams) => {
    try {
      const response = await getUserInfo({ token, service_id: config.SERVICE_ID });
      if (!response.ok) {
        showDialog("Notice!", [
          "Sorry for the inconvenience, we are currently in maintenance, we will be back in a few minutes.",
        ]);
        return null;
      }

      const { current_usage, quota_limit, limit_frequency, limit_type, plan_id } = await response.json();
      if (quota_limit === -1 || current_usage < quota_limit) {
        if (mode === "create") {
          generateTableMutation.mutate({ type, requestData: queryParams });
          return null;
        } else if (mode === "refresh") {
          const result = await generateTableMutation.mutateAsync({ type, requestData: queryParams });
          if (result.ok) {
            return result;
          }
          return null;
        }
      } else {
        let limitText = limit_frequency === "monthly" ? " monthly " : " daily ";
        limitText += limit_type === "row" ? "number of rows " : "API calls ";

        let message = [
          [
            `You've reached your ${quota_limit} ${limitText} limit. That means you are enjoying our tool! We are really happy Dataslayer is helping you so much!`,
          ],
          [
            "If you are going to need more, please <a href='https://app.dataslayer.ai/my-subscriptions' target='_blank'>UPGRADE YOUR PLAN HERE</a>",
          ],
        ];

        if (plan_id === 1) {
          message.push([
            "<a href='https://www.g2.com/products/dataslayer-ai/reviews/start?return_to=https%3A%2F%2Fwww.g2.com%2Fproducts%2Fdataslayer-ai%2Ftake_survey' target='_blank'>Review us in G2</a>, send us a screenshot via our live chat and we will extend your trial up to 1 month!",
          ]);
        }

        showDialog("Notice!", message);
      }
    } catch (error) {
      showDialog("Notice!", ["Failed to fetch user info. Try again later."]);
    }
  };

  return {
    checkAvailableQueries,
    isLoading: generateTableMutation.isLoading,
  };
};

import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogSurface,
  DialogBody,
  makeStyles,
  Select,
  Input,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  Tag,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Label,
  RadioGroup,
  Radio,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  dialogWidth: {
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
  },
  dialogBody: {
    display: "flex!important",
    flexDirection: "column!important",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  filterRow: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "8px",
    flexDirection: "column",
  },
  deleteButton: {
    minWidth: "10px!important",
  },
  input: {
    width: "35%",
  },
  select: {
    width: "35%",
  },
});

const FilterComponent = ({
  filters,
  conditions,
  onRemove,
  index,
  onLogicChange,
  logic,
  onFieldChange,
  onConditionChange,
  onValueChange,
  initialField,
  initialCondition,
  initialValue,
}) => {
  const styles = useStyles();
  const [value, setValue] = React.useState(initialValue || "");
  const [selectedFilter, setSelectedFilter] = React.useState(initialField || filters[0]?.id || "");
  const selectedConditionObject = Object.values(conditions)
    .flat()
    .find((item) => item.id === initialCondition);
  const [selectedCondition, setSelectedCondition] = React.useState(
    selectedConditionObject ? { id: selectedConditionObject.id, name: selectedConditionObject.name } : null
  );
  const [filterCondition, setFilterCondition] = React.useState("");

  const onOptionConditionSelect = (e, data) => {
    const selected = tagPickerConditions
      .flatMap((group) => group.items)
      .find((item) => item.id === data.selectedOptions[0]);

    if (selected) {
      setSelectedCondition({ id: selected.id, name: selected.name });
      onConditionChange(index, selected.id);
    } else {
      setSelectedCondition(null);
    }
  };

  const handleFieldChange = (e) => {
    setSelectedFilter(e.target.value);
    onFieldChange(index, e.target.value);
  };

  const handleValueChange = (e) => {
    setValue(e.target.value);
    onValueChange(index, e.target.value);
  };

  const tagPickerConditions = React.useMemo(() => {
    return Object.entries(conditions).reduce((acc, [group, items]) => {
      const filteredConditionsColsItems = items.filter((item) => item.name.toLowerCase().includes(filterCondition));
      if (filteredConditionsColsItems.length > 0) {
        acc.push({ group, items: filteredConditionsColsItems });
      }
      return acc;
    }, []);
  }, [conditions, filterCondition]);

  return (
    <div className={styles.container}>
      {index > 0 && (
        <div className={styles.logicRow}>
          <RadioGroup value={logic} onChange={(e) => onLogicChange(index, e.target.value)}>
            <Radio value="AND" label="AND" checked={logic === "AND"} />
            <Radio value="OR" label="OR" checked={logic === "OR"} />
          </RadioGroup>
        </div>
      )}
      <div className={styles.filterRow}>
        <div id="filter-option-select" className="w-full">
          <TagPicker
            className="w-full"
            positioning={{ position: "below", align: "bottom" }}
            onOptionSelect={onOptionConditionSelect}
            selectedOptions={selectedCondition ? [selectedCondition.id] : []}
          >
            <TagPickerControl>
              <TagPickerGroup>
                {selectedCondition && (
                  <TagPickerGroup>
                    <Tag key={selectedCondition.id} shape="rounded" value={selectedCondition.id}>
                      {selectedCondition.name}
                    </Tag>
                  </TagPickerGroup>
                )}
              </TagPickerGroup>
              <TagPickerInput
                aria-label="Select Employees"
                onChange={(e) => setFilterCondition(e.target.value.toLowerCase())}
              />
            </TagPickerControl>
            <TagPickerList>
              {tagPickerConditions.length > 0
                ? tagPickerConditions.map(({ group, items }) => (
                    <React.Fragment key={group}>
                      <Label id="dim-row-label" className={styles.groupName}>
                        {group}
                      </Label>
                      {items.map((item) => (
                        <TagPickerOption secondaryContent="" value={item.id} text={item.name} key={item.id}>
                          {item.name}
                        </TagPickerOption>
                      ))}
                    </React.Fragment>
                  ))
                : "No options available"}
            </TagPickerList>
          </TagPicker>
        </div>

        <div className="flex justify-between w-full">
          <Select className={styles.select} value={selectedFilter} onChange={handleFieldChange}>
            {filters.map((filter) => (
              <option key={filter.id} value={filter.id}>
                {filter.name}
              </option>
            ))}
          </Select>

          <Input className={styles.input} placeholder="Type value" value={value} onChange={handleValueChange} />

          <Button className={styles.deleteButton} onClick={onRemove}>
            <DismissRegular />
          </Button>
        </div>
      </div>
    </div>
  );
};

const FiltersDialog = ({ open, handleDiscard, handleApply, dimensions, filtersList, initialFilters }) => {
  const styles = useStyles();
  const [filters, setFilters] = React.useState(initialFilters || []);

  React.useEffect(() => {
    setFilters(initialFilters || []);
  }, [initialFilters]);

  const addFilter = () => {
    setFilters([...filters, { logic_gate: filters.length === 0 ? "" : "AND", operator: "", dimension: "", value: "" }]);
  };

  const removeFilter = (index) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const handleLogicChange = (index, newLogic) => {
    const updatedFilters = [...filters];
    updatedFilters[index].logic_gate = newLogic;
    setFilters(updatedFilters);
  };

  const handleFieldChange = (index, newField) => {
    const updatedFilters = [...filters];
    updatedFilters[index].operator = newField;
    setFilters(updatedFilters);
  };

  const handleConditionChange = (index, newCondition) => {
    const updatedFilters = [...filters];
    updatedFilters[index].dimension = newCondition;
    setFilters(updatedFilters);
  };

  const handleValueChange = (index, newValue) => {
    const updatedFilters = [...filters];
    updatedFilters[index].value = newValue;
    setFilters(updatedFilters);
  };

  const applyFilters = () => {
    handleApply(filters);
  };

  const discardFilters = () => {
    handleDiscard();
    setFilters([]);
  };

  return (
    <Dialog open={open}>
      <DialogSurface className={styles.dialogWidth}>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle className={styles.dialogTitle}>
            Filters
            <div className="gap-3 flex">
              <Button appearance="primary" size="small" onClick={applyFilters}>
                Apply
              </Button>
              <Button onClick={handleDiscard} size="small">
                Discard
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            {filters.length === 0 ? (
              <Button appearance="transparent" className={styles.addButton} onClick={addFilter}>
                + Add filter
              </Button>
            ) : (
              <div>
                {filters.map((filter, index) => (
                  <FilterComponent
                    key={index}
                    filters={filtersList}
                    conditions={dimensions}
                    index={index}
                    logic={filter.logic_gate}
                    initialField={filter.operator}
                    initialCondition={filter.dimension}
                    initialValue={filter.value}
                    onRemove={() => removeFilter(index)}
                    onLogicChange={handleLogicChange}
                    onFieldChange={handleFieldChange}
                    onConditionChange={handleConditionChange}
                    onValueChange={handleValueChange}
                  />
                ))}
                <Button appearance="transparent" className={styles.addButton} onClick={addFilter}>
                  + Add filter
                </Button>
              </div>
            )}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
export default React.memo(FiltersDialog);
